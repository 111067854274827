import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "events",
      "style": {
        "position": "relative"
      }
    }}>{`Events`}<a parentName="h2" {...{
        "href": "#events",
        "aria-label": "events permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Events describe activities happening within your business. Events are always associated with a Profile, and sometimes
collected as a Session. `}<br /><a className="mt-3 text-decoration-none" href="/docs/items/events/">{`Learn More About Events`}<i className="fe fe-arrow-right ms-1"></i></a></p>
    <h2 {...{
      "id": "sessions",
      "style": {
        "position": "relative"
      }
    }}>{`Sessions`}<a parentName="h2" {...{
        "href": "#sessions",
        "aria-label": "sessions permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Sessions keep track of a Profile's Events within a logical period of time - usually when browsing a website or
accomplishing some tasks in an application. `}<br /><a className="mt-3 text-decoration-none" href="/docs/items/sessions/">{`Learn More About Sessions`}<i className="fe fe-arrow-right ms-1"></i></a></p>
    <h2 {...{
      "id": "profiles",
      "style": {
        "position": "relative"
      }
    }}>{`Profiles`}<a parentName="h2" {...{
        "href": "#profiles",
        "aria-label": "profiles permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Profiles represent the individual humans that perform Event-generating activities collected in Firstparty. `}<br /><a className="mt-3 text-decoration-none" href="/docs/items/profiles/">{`Learn More About Profiles`}<i className="fe fe-arrow-right ms-1"></i></a></p>
    <h2 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h2" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`All Firstparty Data Objects expose a customizable `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`properties`}</code>{` object on the Object. Object Properties are extremely
flexible, and you may set any data you'd like on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`properties`}</code>{` key of any Object.`}</p>
    <blockquote>
      <p parentName="blockquote">{`You may specify a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`properties`}</code>{` object when sending an Event to be collected by Firstparty. Different Source types will require different methods of setting `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`properties`}</code>{`.`}</p>
      <p parentName="blockquote">{`As Firstparty collects Events, any Rules defined in your Workspace will be evaluated and executed when appropriate. Rules may be used to manipulate the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`properties`}</code>{` of any Event, Session, or Profile.`}</p>
    </blockquote>
    <h2 {...{
      "id": "shared-fields",
      "style": {
        "position": "relative"
      }
    }}>{`Shared Fields`}<a parentName="h2" {...{
        "href": "#shared-fields",
        "aria-label": "shared fields permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`In addition to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`properties`}</code>{` key, Data Objects may expose a number of additional fields:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Item Key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`context`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Describes the context of the collected Event or Session. (More Below)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`created_at`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ISO 8601 timestamp of when the Item was created`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`updated_at`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ISO 8601 timestamp of when the Item was last updated`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "context",
      "style": {
        "position": "relative"
      }
    }}>{`Context`}<a parentName="h3" {...{
        "href": "#context",
        "aria-label": "context permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Events and Sessions may include an optional Context object, helps describe the environment the Event was collected in a standardized format.
Context includes data such as Browser details, Device details, Operating System, Screen Size, Time Zone, Geography, etc.`}</p>
    <p>{`Firstparty.js automatically populates several Context fields for every collected Event.
When sending an event to Firstparty using the HTTP API the Context is optional but should be considered a good practice to include.`}</p>
    <h4 {...{
      "id": "location",
      "style": {
        "position": "relative"
      }
    }}>{`Location`}<a parentName="h4" {...{
        "href": "#location",
        "aria-label": "location permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h4>
    <p>{`Firstparty will use the IP address an Event's payload was sent from and attempt to fill the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`context.location`}</code>{` object
`}<em parentName="p">{`if it is empty`}</em>{`.`}</p>
    <h4 {...{
      "id": "example-context-object",
      "style": {
        "position": "relative"
      }
    }}>{`Example Context Object`}<a parentName="h4" {...{
        "href": "#example-context-object",
        "aria-label": "example context object permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h4>
    <p>{`Here is a sample of a complete Context object in JSON:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"context"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"app"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Support"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"version"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2.1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"build"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"14809"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"namespace"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"com.domain.support"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"campaign"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Campaign Name"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"source"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"social"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"medium"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"twitter"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"term"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"keyword"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"content"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"image link"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"device"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"guid-guid-guid-guid"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"advertising_id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"guid-guid-guid-guid"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"ad_tracking_enabled"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"manufacturer"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Apple"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"model"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"iPhone 13,1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Iphone"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ios"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"token"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"guid-guid-guid-guid"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"ip"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"123.123.123.123"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"locale"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"en-US"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"location"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"city"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Scottsdale"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"country"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"USA"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"continent"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"North America"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"postal"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"subdivision"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"latitude"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"33.494978"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"longitude"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-111.926127"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"is_european_union"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"network"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"bluetooth"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"carrier"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"T-Mobile"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"cellular"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"wifi"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"os"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"iPhone OS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"version"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"13.0.1"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"browser"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Safari"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"version"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"15.1"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"page"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"path"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/support/chat"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"referrer"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/support"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"search"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"corporis"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"title"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Support Chat"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"url"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://app.domain.com/support/chat"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"screen"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"height"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1750"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"width"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1150"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"timezone"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"America/Phoenix"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"useragent"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Mozilla/5.0 AppleWebKit/537.36 (KHTML, like Gecko)"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"bot"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"is_bot"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      